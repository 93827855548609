/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

%c-btn,
.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.9rem 1.2rem 0.7rem;
  min-width: 10rem;
  transition: $global-transition;
  border-radius: $global-radius;
  border: 1px solid transparent;
  white-space: nowrap;
  touch-action: manipulation;
  user-select: none;
  width: auto;
  font-family: $font-sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 0.8rem;
  line-height: 1rem;

  @include bp-down(small) {
    font-size: 12px;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &.is-disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}

/* Style variants - very important
   ========================================================================== */

%c-btn--primary,
.c-btn--primary {
  color: $color-white;
  background-color: $color-btn-primary;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-navy;
    text-decoration: none; /* [4] */
    color: $color-navy;
    background-color: $color-white;
  }
}

%c-btn--secondary,
.c-btn--secondary {
  color: $color-navy;
  background-color: $color-white;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-white;
    text-decoration: none; /* [4] */
    color: $color-white;
    background-color: $color-navy;
  }
}

@property --p {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

@property --r {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

%c-btn--cta,
.c-btn--cta {
  /*  needed for firefox to have a valid output */
  --p: 0%;
  --r: 0%;

  transition: --p 800ms, --r 800ms;
  // background:conic-gradient(at var(--p) 50%, $color-btn-cta, #ffcf0d, #ffad29);
  color: $color-white;
  // background-color: $color-btn-cta;
  background: conic-gradient(
    at var(--p) var(--r),
    $color-btn-cta,
    #ffaa21,
    #ffad29,
    $color-btn-cta
  );

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-btn-cta;
    text-decoration: none; /* [4] */
    // color: $color-btn-cta;
    color: $color-white;

    --p: 125%;
    --r: 100%;
  }
}

/* Size variants
   ========================================================================== */

%c-btn--small,
.c-btn--small {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--block {
  display: block;
  width: 100%;

  + .c-btn--block {
    margin-top: calc($spacing / 4);
  }
}

.c-btn--link {
  background-color: transparent;
  color: $color-brand;
}

.c-buttons {
  margin-top: $spacing * 2;
  display: flex;
  gap: $spacing;
  justify-content: center;

  @include bp-down(small) {
    flex-direction: column;
  }
}

.c-buttons:has(p) {
  flex-direction: column;
  align-items: center;

  .c-btn--text {
    font-size: 90%;
  }
}

.menu-item--btn {
  position: relative;

  @include bp(large) {
    top: -3px;
  }
}

.menu-item--btn .c-main-nav__link {
  @extend %c-btn;
  @extend %c-btn--cta;

  min-width: auto;
  font-size: 0.7rem;
}
