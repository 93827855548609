.c-columns__images {
  display: flex;

  @include bp(medium) {
    margin-top: calc(-1 * $spacing);
    margin-bottom: calc(-1 * $spacing);
  }
}

.c-columns__picture {
  flex: 1 0 50%;
  position: relative;
}

.c-columns__image {
  width: 100%;
  height: auto;
  border-radius: $global-radius;
}

.c-columns__images--1 .c-columns__image {
  max-width: 650px;
  margin: 0 auto;
  display: block;
}

.c-columns__picture + .c-columns__picture:last-child {
  .c-columns__image {
    position: relative;
    left: -20%;
    margin-top: 50%;

    @include bp-down(medium) {
      left: -18%;
      width: 120%;
      max-width: none;
    }
  }
}
