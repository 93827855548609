/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  display: flex;
  flex-direction: column;
  gap: var(--layout-gap);

  @include bp(medium) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.o-layout__item {
  flex-grow: 1;
  flex-shrink: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--reverse {
  @include bp(medium) {
    .o-layout__item:first-child {
      order: 2;
    }
  }
}

.o-layout--vcenter {
  @include bp(medium) {
    align-items: center;
  }
}

.o-layout--1 {
  gap: 0;
  justify-content: center;
}

.o-layout--narrow .o-layout__item {
  max-width: 660px;
}

.o-layout--full .o-layout__item {
  max-width: 100%;
}

.o-layout--wide .o-layout__item {
  max-width: var(--container);
}

.o-layout--2 {
  > .o-layout__item {
    @include bp(medium) {
      width: calc(50% - var(--layout-gap));
    }
  }
}

.c-heading + .o-layout--2 {
  margin-top: var(--section-spacing);
}

.o-layout--3 {
  > .o-layout__item {
    @include bp(medium) {
      width: calc(33% - var(--layout-gap));
    }
  }
}

.o-layout--4 {
  > .o-layout__item {
    @include bp(medium) {
      width: 24%;
    }
  }
}
