.c-infobar {
  padding: 0.75em 0;
  font-size: 0.8rem;
  line-height: 1;
  color: $color-white;
  text-align: center;
  background-color: $color-navy;

  a {
    text-decoration: underline;
  }

  a,
  a:hover {
    color: $color-white;
  }

  a:hover {
    text-decoration: none;
  }

  .woocommerce-page:not(.woocommerce-account) & {
    display: none;
  }
}

.c-infobar--pulse {
  position: relative;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    // background-color: $color-purple;
    z-index: -1;
    // box-shadow: 0 0 0 3px $color-green;
    // animation: pulseBar 4s infinite;
  }
}

// @keyframes pulseBar {
//   0% {
//     background-color: $color-green;
//     box-shadow: 0 0 0 3px inherit;
//   }

//   40% {
//     background-color: $color-orange;
//   }

//   60% {
//     box-shadow: 0 0 0 3px inherit;
//   }

//   70% {
//     background-color: $color-purple;
//     box-shadow: 0 0 0 8px transparent;
//   }

//   100% {
//     background-color: $color-green;
//     box-shadow: 0 0 0 3px inherit;
//   }
// }
