section[class*='--cream'] {
  background: conic-gradient(
    from -90deg at bottom center,
    $color-cream-lighter,
    $color-cream
  );
}

section[class*='--navy'] {
  background: conic-gradient(
    from -90deg at bottom center,
    $color-navy-lighter,
    $color-navy
  );

  .c-wysiwyg,
  .main-title,
  .main-title a,
  .secondary-title,
  p,
  .c-tease .read-more {
    color: $color-white;
  }

  .c-btn {
    @extend %c-btn--secondary;
  }
}

section[class*='--white'] {
  background-color: $color-white;
}

section[class*='c-section']:not(.c-quote) {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);

  .c-buttons {
    margin-top: var(--section-spacing);
  }
}

section[class*='c-gallery'] .c-heading + .c-buttons {
  @include bp(medium) {
    margin-top: -2rem;
    margin-bottom: 3rem;
  }

  @include bp-down(medium) {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
}
