/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-web~assets/fonts/#font-display
 */

@font-face {
  font-family: berlin;
  src: url('~assets/fonts/berlin-regular-webfont.woff2') format('woff2'),
    url('~assets/fonts/berlin-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
