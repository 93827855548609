/* stylelint-disable */
input,
select,
textarea {
  border-radius: $global-radius;
  background-color: $color-cream;
  border: 1px solid $color-black;
  color: $color-text;
  font-family: $font-serif;
  font-size: var(--font-size);
  line-height: 1rem;
  padding: 0.7rem 1.2rem;
  width: 100%;
  transition: border 0.2s ease-in-out;

  section[class*='--navy'] {
    border-color: $color-cream;
  }

  &:focus,
  &:hover {
    border-color: $color-text;
  }

  &.wpcf7-not-valid {
    border-color: $color-danger;
  }
}

input[type='checkbox'],
input[type='radio'] {
  background: transparent;
  border: 0;
  line-height: normal;
  height: auto;
  width: auto;
}

input[type='file'] {
  line-height: normal;
  height: auto;
  padding: $spacing;
}

input[disabled] {
  background-color: whitesmoke;
  cursor: not-allowed;
}

select {
  line-height: normal;
  padding: 0;
  padding-left: $spacing;
}

textarea {
  min-height: $spacing * 12;
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  max-width: 100%;
}



/**
 * Form utility classes
 */

.form,
.wpcf7-form {
  .form__group {
    position: relative;
    margin-bottom: calc($spacing * 1.5);

    .form__label {
      margin-bottom: 0.8rem;
    }

    &,
    label,
    .form__label,
    .wpcf7-form-control-wrap {
      display: block;
    }

    input[type='checkbox'],
    input[type='radio'] {
      position: absolute;
      visibility: hidden;
    }

    input[type='checkbox'] + .form__label,
    input[type='radio'] + .form__label {
      display: inline-block;
      position: relative;
      padding-left: 2rem;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        margin-right: 1rem;
        width: 1rem;
        height: 1rem;
        border: 1px solid $color-black;
        background-color: $color-white;
        transition: background-color 0.2s ease-in-out;
      }
    }

    input[type='radio'] + .form__label {
      &::before {
        border-radius: 50%;
      }
    }

    input[type='checkbox']:checked + .form__label,
    input[type='radio']:checked + .form__label {
      &::before {
        box-shadow: 0 0 0 1px $color-black;
        border-color: $color-white;
        background-color: $color-black;
      }
    }
  }
}

.wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.form__label {
  @extend .secondary-title;

  font-size: 0.8rem;
}

.form--inline {
  .form__group {
    display: inline-block;
  }
}

.wpcf7-not-valid-tip {
  padding: 0.5rem;
  color: $color-danger;
}

.wpcf7-submit,
.c-btn.wpcf7-submit {
  display: block;
  width: 100%;

  .submitting & {
    color: $color-text;
  }

  & + .ajax-loader {
    position: absolute;
    top: 10px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
    background-color: $color-bg;

    &::before {
      background-color: $color-black;
    }
  }
}

.wpcf7.wpcf7 form .wpcf7-response-output {
  padding: $spacing;
  text-align: center;
  font-size: 0.8rem;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  border-color: $color-danger;
}

.col + .col .wpcf7 {
  @include bp(small) {
    margin-top: 2 * $spacing;
  }
}
/* stylelint-enable */
