/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: sticky;
  top: 0;
  left: var(--page-border-width);
  width: calc(100% - 2 * var(--page-border-width));
  height: var(--header-height);
  background-color: $color-bg;
  transition: $global-transition;
  z-index: 2;

  &.is-scrolled {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .page-template-template-landing-no-header & {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

/**
 * Hamburger menu
 */

.c-header__burger {
  position: absolute;
  top: 50%;
  right: $spacing;
  z-index: 550;
  transition: $global-transition;
  transform: translateY(-50%);
  display: none;
  border: 0;
  background: none;

  @include bp-down(large) {
    display: block;
  }
}

.c-header__burger-line {
  width: 30px;
  height: 2px;
  background-color: $color-brand;
  display: none;
  margin: 6px auto;
  transition: $global-transition;

  @include bp-down(large) {
    display: block;
  }

  .c-header__burger.is-clicked & {
    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    &:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

// Mobile navigation
.c-header__navigation {
  @include bp-down(large) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: $global-transition;

    .nav-is-open & {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      z-index: 1;
      overflow: auto;
    }
  }
}
