/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 */

body:not(.page-template-template-landing-no-header) {
  position: relative;
}
