.c-pagination {
  margin-top: var(--section-spacing);

  a {
    color: $color-black;
  }
}

.c-pagination .page-number {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  aspect-ratio: 1;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  font-family: $font-sans-serif;
  font-weight: 700;
  color: $color-black;
  background: transparent;
  text-decoration: none;
  transition: $global-transition;

  @supports not (aspect-ratio: auto) {
    height: 30px;
  }

  &:hover,
  &.current {
    border-bottom: 1px solid $color-black;
  }
}

.c-pagination,
.c-pagination__list {
  display: flex;
  justify-content: center;
  gap: $spacing;
}

.c-pagination__list {
  list-style: none inside;
}
