.c-faq__wrapper {
  display: flex;
  flex-direction: column;
  // gap: 10px;
}

.c-faq__title,
.c-faq__content {
  line-height: 1.2;
}

.c-faq__title {
  @extend %secondary-title;

  border-bottom: 1px solid $color-cream;
  padding: 0.8rem 1.5rem;
  position: relative;
  display: flex;
  gap: $spacing;
  font-size: 1rem;
  background-color: $color-white;
  cursor: pointer;
  transition: $global-transition;

  &::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    content: '';

    @include chevron();

    transform: rotate(-90deg);
    transition: $global-transition;
  }

  &[aria-expanded='true'] {
    background-color: $color-cream;

    &::before {
      transform: rotate(0deg);
    }
  }
}

.c-faq__content {
  display: grid;
  grid-template-rows: 0fr;
  // transition: grid-template-rows 0.5s ease-in-out, padding 0.5s ease-in-out;
  font-size: 0.9rem;
  margin: 0;
  transition: $global-transition;

  &:last-child {
    border: none;
  }

  > div {
    overflow: hidden;
    padding: 0 $spacing;
    // margin-bottom: $spacing;
  }

  [aria-expanded='true'] + & {
    grid-template-rows: 1fr;
    padding-bottom: $spacing;
    padding-top: $spacing;
    background-color: $color-cream;
  }
}
