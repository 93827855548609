.c-gallery {
  .c-wysiwyg {
    padding: $spacing;
  }
}

.c-gallery__picture {
  margin: 5px 0;
  position: relative;

  @include bp-down(small) {
    width: calc(100% - 10px) !important;
  }

  &.is-loading::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url('~assets/images/icon-tail-spin.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.c-gallery__img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

[data-columns='2'] {
  .grid-sizer,
  .c-gallery__picture {
    width: calc(50% - 10px);
  }

  .c-gallery__picture.width2,
  .c-gallery__picture.width3,
  .c-gallery__picture.width4 {
    width: calc(100% - 10px);
  }
}

[data-columns='3'] {
  .grid-sizer,
  .c-gallery__picture {
    width: calc(33% - 10px);
  }

  .c-gallery__picture.width2 {
    width: calc(66% - 10px);
  }

  .c-gallery__picture.width3,
  .c-gallery__picture.width4 {
    width: calc(100% - 10px);
  }
}

[data-columns='4'] {
  .grid-sizer,
  .c-gallery__picture {
    width: calc(25% - 10px);
  }

  .c-gallery__picture.width2 {
    width: calc(50% - 10px);
  }

  .c-gallery__picture.width3 {
    width: calc(75% - 10px);
  }

  .c-gallery__picture.width4 {
    width: calc(100% - 10px);
  }
}

.gallery-item .gallery-icon {
  display: block;
  width: 100%;
  height: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-color: transparent !important;
  }
}

.c-gallery__picture.grid-item--width2,
.c-gallery__picture.grid-item--width2 img {
  width: 100%;
}
