/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.nav--header {
  .current-menu-item a,
  a:hover {
    &::before {
      width: 100%;
    }
  }
}

.c-main-nav {
  display: flex;
  align-items: baseline;
  margin: 0 0 2em 0;
  gap: 0.5rem;

  @include bp-down(large) {
    padding: var(--header-height) 0;
    display: block;
  }

  @media (max-height: 540px) {
    margin: auto;
  }
}

.c-main-nav__item,
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}

.c-main-nav__subitem {
  @include bp(large) {
    border-bottom: 1px solid $color-black;
  }
}

.c-main-nav__sublink {
  transition: all 0.3s ease-in-out;

  &:hover {
    @include bp(large) {
      color: $color-white;
      background-color: $color-navy;
    }
  }

  @include bp-down(large) {
    padding: 1rem 0.25rem 0.5rem 1.25rem;
  }

  @media (max-height: 540px) {
    padding: 0.5rem 0.25rem 0.15rem;
  }
}

/* stylelint-disable */
.c-main-nav__link,
.c-main-nav__sublink {
  position: relative;
  display: block;
  padding: 0.5rem 0.65rem 0.5rem;
  text-transform: uppercase;
  font-family: $font-sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;

  @include bp-down(small) {
    font-size: 12px;
  }

  &[type='button'] {
    background: none;
    border: 0;
  }

  &:hover {
    text-decoration: none;
  }

  .current-menu-item & {
    border-bottom-color: $color-black;
  }

  @media (max-height: 540px) {
    padding: 0.5rem 0.25rem 0.15rem;
  }
}

.menu-item-has-children {
  position: relative;

  .c-main-nav__link {
    // Chevron
    &::after {
      content: '';
      width: 14px;
      height: 10px;
      display: inline-block;
      @include chevron();
    }
  }
}

.c-main-nav__link {
  //Underline:
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid transparent;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    z-index: -1;
  }

  .current-menu-item & {
    &::before {
      height: 100%;
      border-bottom-color: $color-black;
      // background-color: $color-white;
    }
  }
  .menu-item:hover & {
    &::before {
      height: 100%;
      border-bottom-color: $color-black;
      background-color: $color-white;
    }
  }
}

/* stylelint-enable */

.c-main-nav__dropdown-wrapper {
  width: 100%;
  min-width: 180px;
  transition: all 0.3s ease-in-out;
  display: block;
  will-change: max-height;
  overflow: hidden;

  // Desktop styles
  @include bp(large) {
    max-height: 0;
    position: absolute;
    background-color: $color-white;
  }

  .c-main-nav__item.menu-item-has-children:hover & {
    @include bp(large) {
      max-height: 300px;
    }
  }

  // Mobile styles
  @include bp-down(large) {
    padding-left: 1.5rem;
  }
}
