.c-embed--youtube {
  margin: 0 auto;
  max-width: 1024px;

  iframe {
    width: 100%;
    aspect-ratio: 1280/720;
    height: auto;
  }
}

@supports not (aspect-ratio: auto) {
  .c-embed--youtube {
    padding-top: 56%;
    height: 0;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
