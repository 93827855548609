.c-featured-images {
  margin-bottom: var(--heading-spacing);

  .c-main &:first-child {
    padding-top: var(--heading-spacing);
    margin-bottom: 0;
  }
}

.c-featured-images__gallery {
  display: flex;
  gap: $spacing;
  max-width: var(--wide-container);
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  overflow: hidden;
  justify-content: center;

  .o-wrapper + & {
    margin-top: var(--heading-spacing);
  }

  .glide__slides {
    align-items: center;
  }
}

.c-featured-images__gallery:not(:has(.glide__track), .c-featured-images__gallery--3) {
  @include bp-down(small) {
    flex-wrap: wrap;

    > * {
      flex: 1 1 45%;
    }
  }
}
/* stylelint-disable */
section[class*='--cream'] + .c-featured-images,
section[class*='--navy'] + .c-featured-images {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 50%;
    left: 50%;
    transform: translateX(-50%);
    transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
  }
}

section[class*='--cream'] + .c-featured-images {
  &::before {
    background: conic-gradient(
      from -90deg at top center,
      $color-cream,
      $color-cream-lighter
    );
  }
}

section[class*='--navy'] + .c-featured-images {
  &::before {
    background: conic-gradient(
      from -90deg at top center,
      $color-navy,
      $color-navy-lighter
    );
  }
}
/* stylelint-enable */

.c-featured-images__img {
  border-radius: $global-radius;
}

.c-featured-images__gallery--2 {
  justify-content: center;

  @include bp(medium) {
    > * {
      max-width: 40vw;
    }
  }

  @include bp(large) {
    > * {
      max-width: 25vw;
    }
  }
}

// .c-featured-images__gallery--3 {
//   justify-content: center;

//   @include bp-down(medium) {
//     > * {
//       flex: 1 1 33%;
//     }
//   }
// }
