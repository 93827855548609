// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'berlin', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;
$font-serif: charter, 'Bitstream Charter', 'Sitka Text', cambria, serif;
$base-font-size: 20px;
$base-line-height: 1.5;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 520px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  full: 1600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #1f1f1f;
$color-navy: #363f4d; // #22324a;
$color-navy-lighter: #394352;
$color-cream: #f7f5f3; // #f9f5f1;
$color-cream-lighter: #faf7f5;
$color-grey: #707070;
$color-accent: #f9a620;

// Text
$color-bg: $color-white;
$color-bg-secondary: $color-cream;
$color-text: $color-black;

// Links
$color-link: $color-navy;
$color-hover: $color-navy;

$color-btn-primary: $color-navy;
$color-btn-cta: $color-accent;

// Borders
$color-border: $color-grey;
$color-page-border: $color-navy;

// Fills
$color-fill: $color-grey;

// Specials
$color-brand: $color-navy;
$color-success: #5cb85c;
$color-info: $color-brand;
$color-warning: #f0ad4e;
$color-danger: #d9534f;

/* Spacings
   ========================================================================== */
$spacing: 1rem;

/* CSS variables
   ========================================================================== */
:root {
  --font-size: 14px;
  --page-border-width: 1px;
  --default-spacing: 1rem;
  --header-height: 50px;
  --logo-width: 110px;
  --section-spacing: calc(3 * 1rem);
  --heading-spacing: calc(2 * 1rem);
  --layout-gap: calc(2 * 1rem);
  --wide-container: 2000px;
  --container: 1400px;

  @media (min-width: map-get($breakpoints, 'small')) {
    --font-size: 16px;
    // --page-border-width: 0;
    --heading-spacing: calc(3 * 1rem);
  }

  @media (min-width: map-get($breakpoints, 'medium')) {
    --font-size: 18px;
    // --page-border-width: 0;
    --header-height: 90px;
    --logo-width: 170px;
    --section-spacing: calc(5 * 1rem);
  }

  @media (min-width: map-get($breakpoints, 'large')) {
    --font-size: 20px;
    // --page-border-width: 0;
    --section-spacing: calc(7 * 1rem);
  }

  @media (min-width: map-get($breakpoints, 'full')) {
    // comment
  }
}
