@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.glide__bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 1;

  .c-hero__slider & {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    width: 100%;

    @include bp(medium) {
      bottom: 10px;
    }
  }
}

.glide__bullet {
  padding: 0;
  width: 8px;
  height: 8px;
  display: block;
  border: 0;
  border-radius: 50%;
  background-color: $color-fill;

  &--active {
    background-color: $color-navy;
  }
}
