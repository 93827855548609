/* stylelint-disable */
.c-categories-nav {
  @extend %secondary-title;

  position: relative;
  padding-bottom: $spacing;
  background-color: $color-cream;

  .secondary-title,
  .cat-item {
    margin: 0;
  }

  .cat-item {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1 * $spacing;
      width: 100%;
      height: 1px;
      background-color: transparent;
    }

    a {
      color: $color-grey;
      transition: $global-transition;

      &:hover {
        color: $color-black;
      }
    }
  }

  .cat-item.current-cat {
    a {
      color: $color-black;
    }

    &::after {
      background-color: $color-grey;
    }
  }
}

.c-categories-nav__wrapper {
  margin-bottom: var(--heading-spacing);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey;
  padding-bottom: $spacing;
}

.c-categories-nav__list {
  display: flex;
  gap: $spacing;

  a {
    text-decoration: none;
  }
}
/* stylelint-enable */
