/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

small {
  font-size: 0.75em;
}

%main-title,
.main-title {
  display: block;
  font-family: $font-serif;
  font-style: italic;
  font-weight: 700;
  color: $color-black;
  font-size: 1em;
  line-height: 1.2;

  em {
    font-weight: 500;
    font-style: normal;
  }
}

%secondary-title,
.secondary-title {
  color: $color-grey;
  font-family: $font-sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.1;

  @include bp-down(small) {
    font-size: 12px;
  }
}

.c-wysiwyg {
  font-size: 1rem;
  line-height: 1.5;

  @include bp-down(small) {
    font-size: 1.2rem;
  }

  p {
    margin-top: 2 * $spacing;

    @include bp-down(medium) {
      margin-top: $spacing;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2 * $spacing;

    &:first-child {
      margin-top: 0;
    }

    + p {
      margin-top: 0;
    }
  }

  p:first-child {
    margin-top: 0;
  }

  ul,
  ol,
  blockquote {
    margin-left: 3 * $spacing;
    margin-right: 3 * $spacing;
  }

  li {
    margin-bottom: 1 * $spacing;
  }

  blockquote {
    position: relative;
    margin-top: 3 * $spacing;
    padding: 2rem;
    background-color: $color-cream;

    &::before {
      content: ''; // ascii code for "
      display: block;
      position: absolute;
      left: -25px;
      top: -15px;
      width: 64px;
      height: 40px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top left;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOTcgNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTk2LjYwOCA0Ny4xNzZjMCA0LjgtMS44MjQgOC45MjgtNS40NzIgMTIuMzg0LTMuNDU2IDMuMjY0LTcuNzc2IDQuODk2LTEyLjk2IDQuODk2LTcuMTA0IDAtMTMuMDU2LTIuNDk2LTE3Ljg1Ni03LjQ4OC00LjYwOC00Ljk5Mi02LjkxMi0xMS40MjQtNi45MTItMTkuMjk2IDAtMTQuNCA1LjU2OC0yNC42NzIgMTYuNzA0LTMwLjgxNkM3OC4xNzYgMi40NCA4NC43MDQuMjMyIDg5LjY5Ni4yMzJjMi4xMTIgMCAzLjE2OC43NjggMy4xNjggMi4zMDQgMCAxLjM0NC0xLjE1MiAyLjMwNC0zLjQ1NiAyLjg4QzczLjQ3MiA5LjY0IDY1LjUwNCAxNy42MDggNjUuNTA0IDI5LjMyYzAgNS4xODQgMS4zNDQgOS42IDQuMDMyIDEzLjI0OC4zODQtNi41MjggNC41MTItOS43OTIgMTIuMzg0LTkuNzkyIDQuMjI0IDAgNy42OCAxLjQ0IDEwLjM2OCA0LjMyIDIuODggMi42ODggNC4zMiA2LjA0OCA0LjMyIDEwLjA4em0tNTIuOTkyIDBjMCA0LjgtMS44MjQgOC45MjgtNS40NzIgMTIuMzg0LTMuNDU2IDMuMjY0LTcuNjggNC44OTYtMTIuNjcyIDQuODk2LTcuMjk2IDAtMTMuMjQ4LTIuNDk2LTE3Ljg1Ni03LjQ4OEMzLjAwOCA1MS43ODQuNzA0IDQ1LjI1Ni43MDQgMzcuMzg0LjcwNCAyMy4xNzYgNi4yNzIgMTMgMTcuNDA4IDYuODU2IDI1LjQ3MiAyLjQ0IDMxLjkwNC4yMzIgMzYuNzA0LjIzMmMyLjExMiAwIDMuMTY4Ljc2OCAzLjE2OCAyLjMwNCAwIDEuMzQ0LTEuMTUyIDIuMzA0LTMuNDU2IDIuODhDMjAuNjcyIDkuNjQgMTIuOCAxNy43MDQgMTIuOCAyOS42MDhjMCA0Ljk5MiAxLjM0NCA5LjMxMiA0LjAzMiAxMi45Ni4zODQtNi41MjggNC40MTYtOS43OTIgMTIuMDk2LTkuNzkyIDQuMjI0IDAgNy42OCAxLjQ0IDEwLjM2OCA0LjMyIDIuODggMi42ODggNC4zMiA2LjA0OCA0LjMyIDEwLjA4eiIgZmlsbD0iIzAwMCIvPjwvc3ZnPgo=');
    }
  }

  .wp-caption-text,
  .gallery-caption {
    display: block;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
