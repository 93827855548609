/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: var(--container);
  width: 100%;
  padding: 0 var(--default-spacing);

  .c-header & {
    border-top: var(--page-border-width) solid $color-page-border;
    max-width: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);

    &::after {
      display: none !important;
    }
  }
}

.o-wrapper--wide {
  max-width: var(--wide-container);

  /* stylelint-disable */
  @include bp(medium) {
    .o-layout--2 .o-layout__item:last-child > * {
      max-width: 660px;
    }

    .o-layout--2.o-layout--reverse .o-layout__item:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > * {
        width: 100%;
      }
    }
  }
  /* stylelint-enable */
}

.o-wrapper--body {
  position: relative;

  // Sticky footer settings:
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .c-main {
    flex: 1;
    overflow: hidden;
  }
}

.o-wrapper--page-content {
  position: relative;
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}

*:not(.c-categories-nav) + .o-wrapper--page-content {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -2rem;
    left: 50%;
    width: 1px;
    height: 4rem;
    background-color: $color-black;

    @include bp('medium') {
      height: 8rem;
      top: -4rem;
    }
  }
}
