/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
}

.c-post__content {
  .o-wrapper {
    @include bp(medium) {
      padding: 0 8rem;
    }
  }

  .gallery,
  .size-large {
    width: 100%;

    @include bp(medium) {
      margin-left: -6rem;
      margin-right: -6rem;
      width: calc(100% + 12rem);
    }
  }
}

.c-post__password-form {
  text-align: center;

  input {
    max-width: 360px;

    &[type='submit'] {
      @extend %c-btn--primary;

      cursor: pointer;
    }
  }

  label,
  input {
    display: block;
    margin: 5px auto;
  }
}

.c-post__pagination {
  position: relative;
  padding: var(--heading-spacing) 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-black;

  .prev,
  .next {
    margin: 0 $spacing;
  }

  .next {
    text-align: right;
  }

  &:has(.next):not(:has(.prev)) {
    justify-content: end;
  }
}

.c-post__pagination-link {
  position: relative;
  font-size: calc(1.15 * var(--font-size));
  text-decoration: none;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 1px;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: transparent;
    transition: $global-transition;
  }

  &:hover {
    &::after {
      width: 100%;
      background-color: $color-black;
    }
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
