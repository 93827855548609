.c-tease {
  .main-title a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .c-heading__title {
    @include bp(medium) {
      font-size: 1.5rem;
    }
  }
}

.o-grid__item .c-tease {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    flex: 1;
  }

  > *:last-child {
    position: relative;
    justify-self: flex-end;
  }
}

.c-tease__picture {
  position: relative;
  display: block;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transition: $global-transition;
    pointer-events: none;

    .c-tease--post:hover &,
    .c-tease__link:hover & {
      background-color: transparent;
    }
  }
}

.c-tease__img {
  display: block;
  transform: scale(1);
  transition: $global-transition;

  .c-tease--post:hover &,
  .c-tease__link:hover & {
    transform: scale(1.05);
  }
}

.c-tease__link {
  display: block;
  margin-bottom: $spacing;
}

.c-tease .read-more {
  @extend %secondary-title;

  display: block;
  line-height: 1.5rem;
  text-align: center;
  color: $color-navy;
  font-weight: 700;
  text-decoration: none;
  margin: $spacing auto 0;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 1px;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: transparent;
    transition: $global-transition;
  }

  &:hover {
    text-decoration: none;

    &::after {
      width: 4rem;
      background-color: $color-black;
    }
  }
}
