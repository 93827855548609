.c-heading {
  margin-bottom: var(--heading-spacing);
  text-align: center;

  .c-tease & {
    margin-bottom: $spacing;
  }

  > * {
    margin-bottom: 0.25rem;
  }

  &:last-child,
  > *:last-child {
    margin-bottom: 0;
  }
}

.c-heading__title {
  word-break: break-word;

  @include bp(medium) {
    font-size: 2rem;
    line-height: 0.8;
  }
}

.c-heading__archive-title {
  font-size: 1.75rem;
  word-break: break-word;

  @include bp(medium) {
    font-size: 2.25rem;
  }
}

.c-heading__tagline {
  display: inline-block;
}

.c-heading--page {
  padding-top: calc(var(--section-spacing));
  padding-bottom: var(--section-spacing);
  background-color: $color-cream;
}

.c-heading__link {
  text-decoration: none;
  color: $color-grey;
  transition: $global-transition;
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 1px;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: transparent;
    transition: $global-transition;
  }

  &:hover {
    color: $color-black;

    &::before {
      width: 2rem;
      background-color: $color-black;
    }
  }

  &::after {
    content: ',';
  }

  &:last-child::after {
    content: '';
  }
}

.c-heading__post-thumbnail-picture {
  display: block;
  position: relative;

  @include bp(medium) {
    padding-top: 0;
  }

  img {
    max-height: 80vh;
  }
}

.c-heading--post {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
  text-align: center;
  background-color: $color-cream;

  &:has(.c-heading__post-thumbnail-picture) {
    padding-top: 0;
  }

  .c-heading {
    position: relative;
    padding: 0.75rem 1rem;
    width: auto;
    min-width: 50vw;
    display: inline-block;
    text-align: center;
    z-index: 1;
    background-color: $color-white;

    @include bp(medium) {
      padding: 0.75rem 4rem;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -0.5rem;
      border: 1px solid $color-black;
      z-index: -1;
    }
  }

  /* stylelint-disable */
  .c-heading__post-thumbnail + .o-wrapper .c-heading {
    margin-top: -2rem;
  }
  /* stylelint-enable */
}

.c-heading--tease:not(:has(.secondary-title)) {
  padding-top: calc(var(--heading-spacing) / 2);
}
