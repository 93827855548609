/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  position: relative;
  padding: 2rem 0 1rem;
  font-size: 0.875rem;
  line-height: 1.2;
  background-color: $color-bg-secondary;

  a {
    color: $color-grey;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      color: $color-black;
      text-decoration: underline;
    }
  }

  .nav-is-open & {
    z-index: -1;
  }
}

.main--bg + .c-footer {
  background-color: $color-bg;
}

.c-footer__navigation {
  .c-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $spacing;

    @include bp(small) {
      flex-direction: row;
    }
  }

  .c-menu__item {
    margin: 0;
  }
}

.c-footer__socials + .c-footer__navigation,
.c-footer__navigation + p {
  margin-top: $spacing;
}

.c-footer p {
  color: $color-grey;
  text-align: center;
}

.c-footer__socials {
  text-align: center;

  .socials__link {
    display: inline-block;
  }

  .socials__icon {
    display: block;
    font-size: 1.4rem;

    svg {
      position: relative;
      display: inline-block;
      width: 1em;
      height: 1em;
      fill: currentColor;
      vertical-align: middle;
    }
  }
}

.c-footer__copyright {
  margin-top: var(--layout-gap);
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;

  @include bp-down(small) {
    flex-direction: column;
  }
}
