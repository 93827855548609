@charset "UTF-8";
/*
  Project: Ewa Kara
  Author: Ewa Karaszkiewicz
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Spacings
   ========================================================================== */
/* CSS variables
   ========================================================================== */
:root {
  --font-size: 14px;
  --page-border-width: 1px;
  --default-spacing: 1rem;
  --header-height: 50px;
  --logo-width: 110px;
  --section-spacing: calc(3 * 1rem);
  --heading-spacing: calc(2 * 1rem);
  --layout-gap: calc(2 * 1rem);
  --wide-container: 2000px;
  --container: 1400px;
}
@media (min-width: 520px) {
  :root {
    --font-size: 16px;
    --heading-spacing: calc(3 * 1rem);
  }
}
@media (min-width: 768px) {
  :root {
    --font-size: 18px;
    --header-height: 90px;
    --logo-width: 170px;
    --section-spacing: calc(5 * 1rem);
  }
}
@media (min-width: 1024px) {
  :root {
    --font-size: 20px;
    --section-spacing: calc(7 * 1rem);
  }
}
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-web~assets/fonts/#font-display
 */
@font-face {
  font-family: berlin;
  src: url("~assets/fonts/berlin-regular-webfont.woff2") format("woff2"), url("~assets/fonts/berlin-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
*:focus {
  outline-width: 1px;
  outline-style: dashed;
  outline-color: #363f4d;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: var(--default-spacing);
}

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: var(--default-spacing);
}
address:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
blockquote:last-child,
p:last-child,
pre:last-child,
dl:last-child,
ol:last-child,
ul:last-child,
figure:last-child,
hr:last-child,
table:last-child,
fieldset:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 */
body:not(.page-template-template-landing-no-header) {
  position: relative;
}

/* stylelint-disable */
input,
select,
textarea {
  border-radius: 3px;
  background-color: #f7f5f3;
  border: 1px solid #1f1f1f;
  color: #1f1f1f;
  font-family: charter, "Bitstream Charter", "Sitka Text", cambria, serif;
  font-size: var(--font-size);
  line-height: 1rem;
  padding: 0.7rem 1.2rem;
  width: 100%;
  transition: border 0.2s ease-in-out;
}
input section[class*="--navy"],
select section[class*="--navy"],
textarea section[class*="--navy"] {
  border-color: #f7f5f3;
}
input:focus, input:hover,
select:focus,
select:hover,
textarea:focus,
textarea:hover {
  border-color: #1f1f1f;
}
input.wpcf7-not-valid,
select.wpcf7-not-valid,
textarea.wpcf7-not-valid {
  border-color: #d9534f;
}

input[type=checkbox],
input[type=radio] {
  background: transparent;
  border: 0;
  line-height: normal;
  height: auto;
  width: auto;
}

input[type=file] {
  line-height: normal;
  height: auto;
  padding: 1rem;
}

input[disabled] {
  background-color: whitesmoke;
  cursor: not-allowed;
}

select {
  line-height: normal;
  padding: 0;
  padding-left: 1rem;
}

textarea {
  min-height: 12rem;
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  max-width: 100%;
}

/**
 * Form utility classes
 */
.form .form__group,
.wpcf7-form .form__group {
  position: relative;
  margin-bottom: 1.5rem;
}
.form .form__group .form__label,
.wpcf7-form .form__group .form__label {
  margin-bottom: 0.8rem;
}
.form .form__group,
.form .form__group label,
.form .form__group .form__label,
.form .form__group .wpcf7-form-control-wrap,
.wpcf7-form .form__group,
.wpcf7-form .form__group label,
.wpcf7-form .form__group .form__label,
.wpcf7-form .form__group .wpcf7-form-control-wrap {
  display: block;
}
.form .form__group input[type=checkbox],
.form .form__group input[type=radio],
.wpcf7-form .form__group input[type=checkbox],
.wpcf7-form .form__group input[type=radio] {
  position: absolute;
  visibility: hidden;
}
.form .form__group input[type=checkbox] + .form__label,
.form .form__group input[type=radio] + .form__label,
.wpcf7-form .form__group input[type=checkbox] + .form__label,
.wpcf7-form .form__group input[type=radio] + .form__label {
  display: inline-block;
  position: relative;
  padding-left: 2rem;
}
.form .form__group input[type=checkbox] + .form__label::before,
.form .form__group input[type=radio] + .form__label::before,
.wpcf7-form .form__group input[type=checkbox] + .form__label::before,
.wpcf7-form .form__group input[type=radio] + .form__label::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid #1f1f1f;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
}
.form .form__group input[type=radio] + .form__label::before,
.wpcf7-form .form__group input[type=radio] + .form__label::before {
  border-radius: 50%;
}
.form .form__group input[type=checkbox]:checked + .form__label::before,
.form .form__group input[type=radio]:checked + .form__label::before,
.wpcf7-form .form__group input[type=checkbox]:checked + .form__label::before,
.wpcf7-form .form__group input[type=radio]:checked + .form__label::before {
  box-shadow: 0 0 0 1px #1f1f1f;
  border-color: #fff;
  background-color: #1f1f1f;
}

.wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.form__label {
  font-size: 0.8rem;
}

.form--inline .form__group {
  display: inline-block;
}

.wpcf7-not-valid-tip {
  padding: 0.5rem;
  color: #d9534f;
}

.wpcf7-submit,
.c-btn.wpcf7-submit {
  display: block;
  width: 100%;
}
.submitting .wpcf7-submit,
.submitting .c-btn.wpcf7-submit {
  color: #1f1f1f;
}
.wpcf7-submit + .ajax-loader,
.c-btn.wpcf7-submit + .ajax-loader {
  position: absolute;
  top: 10px;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  background-color: #fff;
}
.wpcf7-submit + .ajax-loader::before,
.c-btn.wpcf7-submit + .ajax-loader::before {
  background-color: #1f1f1f;
}

.wpcf7.wpcf7 form .wpcf7-response-output {
  padding: 1rem;
  text-align: center;
  font-size: 0.8rem;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  border-color: #d9534f;
}

@media (min-width: 520px) {
  .col + .col .wpcf7 {
    margin-top: 2rem;
  }
}

/* stylelint-enable */
/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

small {
  font-size: 0.75em;
}

.main-title {
  display: block;
  font-family: charter, "Bitstream Charter", "Sitka Text", cambria, serif;
  font-style: italic;
  font-weight: 700;
  color: #1f1f1f;
  font-size: 1em;
  line-height: 1.2;
}
.main-title em {
  font-weight: 500;
  font-style: normal;
}

.c-tease .read-more, .c-faq__title, .c-categories-nav,
.secondary-title,
.wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.form__label {
  color: #707070;
  font-family: "berlin", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.1;
}
@media (max-width: 519.98px) {
  .c-tease .read-more, .c-faq__title, .c-categories-nav,
.secondary-title,
.wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.form__label {
    font-size: 12px;
  }
}

.c-wysiwyg {
  font-size: 1rem;
  line-height: 1.5;
}
@media (max-width: 519.98px) {
  .c-wysiwyg {
    font-size: 1.2rem;
  }
}
.c-wysiwyg p {
  margin-top: 2rem;
}
@media (max-width: 767.98px) {
  .c-wysiwyg p {
    margin-top: 1rem;
  }
}
.c-wysiwyg h1,
.c-wysiwyg h2,
.c-wysiwyg h3,
.c-wysiwyg h4,
.c-wysiwyg h5,
.c-wysiwyg h6 {
  margin-top: 2rem;
}
.c-wysiwyg h1:first-child,
.c-wysiwyg h2:first-child,
.c-wysiwyg h3:first-child,
.c-wysiwyg h4:first-child,
.c-wysiwyg h5:first-child,
.c-wysiwyg h6:first-child {
  margin-top: 0;
}
.c-wysiwyg h1 + p,
.c-wysiwyg h2 + p,
.c-wysiwyg h3 + p,
.c-wysiwyg h4 + p,
.c-wysiwyg h5 + p,
.c-wysiwyg h6 + p {
  margin-top: 0;
}
.c-wysiwyg p:first-child {
  margin-top: 0;
}
.c-wysiwyg ul,
.c-wysiwyg ol,
.c-wysiwyg blockquote {
  margin-left: 3rem;
  margin-right: 3rem;
}
.c-wysiwyg li {
  margin-bottom: 1rem;
}
.c-wysiwyg blockquote {
  position: relative;
  margin-top: 3rem;
  padding: 2rem;
  background-color: #f7f5f3;
}
.c-wysiwyg blockquote::before {
  content: "";
  display: block;
  position: absolute;
  left: -25px;
  top: -15px;
  width: 64px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOTcgNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTk2LjYwOCA0Ny4xNzZjMCA0LjgtMS44MjQgOC45MjgtNS40NzIgMTIuMzg0LTMuNDU2IDMuMjY0LTcuNzc2IDQuODk2LTEyLjk2IDQuODk2LTcuMTA0IDAtMTMuMDU2LTIuNDk2LTE3Ljg1Ni03LjQ4OC00LjYwOC00Ljk5Mi02LjkxMi0xMS40MjQtNi45MTItMTkuMjk2IDAtMTQuNCA1LjU2OC0yNC42NzIgMTYuNzA0LTMwLjgxNkM3OC4xNzYgMi40NCA4NC43MDQuMjMyIDg5LjY5Ni4yMzJjMi4xMTIgMCAzLjE2OC43NjggMy4xNjggMi4zMDQgMCAxLjM0NC0xLjE1MiAyLjMwNC0zLjQ1NiAyLjg4QzczLjQ3MiA5LjY0IDY1LjUwNCAxNy42MDggNjUuNTA0IDI5LjMyYzAgNS4xODQgMS4zNDQgOS42IDQuMDMyIDEzLjI0OC4zODQtNi41MjggNC41MTItOS43OTIgMTIuMzg0LTkuNzkyIDQuMjI0IDAgNy42OCAxLjQ0IDEwLjM2OCA0LjMyIDIuODggMi42ODggNC4zMiA2LjA0OCA0LjMyIDEwLjA4em0tNTIuOTkyIDBjMCA0LjgtMS44MjQgOC45MjgtNS40NzIgMTIuMzg0LTMuNDU2IDMuMjY0LTcuNjggNC44OTYtMTIuNjcyIDQuODk2LTcuMjk2IDAtMTMuMjQ4LTIuNDk2LTE3Ljg1Ni03LjQ4OEMzLjAwOCA1MS43ODQuNzA0IDQ1LjI1Ni43MDQgMzcuMzg0LjcwNCAyMy4xNzYgNi4yNzIgMTMgMTcuNDA4IDYuODU2IDI1LjQ3MiAyLjQ0IDMxLjkwNC4yMzIgMzYuNzA0LjIzMmMyLjExMiAwIDMuMTY4Ljc2OCAzLjE2OCAyLjMwNCAwIDEuMzQ0LTEuMTUyIDIuMzA0LTMuNDU2IDIuODhDMjAuNjcyIDkuNjQgMTIuOCAxNy43MDQgMTIuOCAyOS42MDhjMCA0Ljk5MiAxLjM0NCA5LjMxMiA0LjAzMiAxMi45Ni4zODQtNi41MjggNC40MTYtOS43OTIgMTIuMDk2LTkuNzkyIDQuMjI0IDAgNy42OCAxLjQ0IDEwLjM2OCA0LjMyIDIuODggMi42ODggNC4zMiA2LjA0OCA0LjMyIDEwLjA4eiIgZmlsbD0iIzAwMCIvPjwvc3ZnPgo=");
}
.c-wysiwyg .wp-caption-text,
.c-wysiwyg .gallery-caption {
  display: block;
  padding: 0.5rem;
  font-size: 0.9rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #1f1f1f;
  font-family: charter, "Bitstream Charter", "Sitka Text", cambria, serif;
  font-weight: 400;
  font-size: var(--font-size);
  /* [1] */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  border: var(--page-border-width) solid #363f4d;
  scroll-behavior: smooth;
}

.nav-is-open {
  overflow: hidden;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}
@media (max-width: 519.98px) {
  .c-wysiwyg img[width],
.c-wysiwyg img[height] {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 520px) {
  .c-wysiwyg .alignleft.size-medium,
.c-wysiwyg .alignright.size-medium {
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .c-wysiwyg .alignleft.size-medium {
    margin-left: -1.5rem;
  }
}

@media (min-width: 1024px) {
  .c-wysiwyg .alignright.size-medium {
    margin-right: -1.5rem;
  }
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #363f4d;
  text-decoration: underline;
}
a:hover {
  color: #363f4d;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 1;
}
.c-hero__slider .glide__bullets {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .c-hero__slider .glide__bullets {
    bottom: 10px;
  }
}

.glide__bullet {
  padding: 0;
  width: 8px;
  height: 8px;
  display: block;
  border: 0;
  border-radius: 50%;
  background-color: #707070;
}
.glide__bullet--active {
  background-color: #363f4d;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
[class*=o-grid] {
  display: grid;
  gap: calc(2 * var(--layout-gap)) var(--layout-gap);
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: flex;
  flex-direction: column;
  gap: var(--layout-gap);
}
@media (min-width: 768px) {
  .o-layout {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.o-layout__item {
  flex-grow: 1;
  flex-shrink: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

@media (min-width: 768px) {
  .o-layout--reverse .o-layout__item:first-child {
    order: 2;
  }
}

@media (min-width: 768px) {
  .o-layout--vcenter {
    align-items: center;
  }
}

.o-layout--1 {
  gap: 0;
  justify-content: center;
}

.o-layout--narrow .o-layout__item {
  max-width: 660px;
}

.o-layout--full .o-layout__item {
  max-width: 100%;
}

.o-layout--wide .o-layout__item {
  max-width: var(--container);
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: calc(50% - var(--layout-gap));
  }
}

.c-heading + .o-layout--2 {
  margin-top: var(--section-spacing);
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: calc(33% - var(--layout-gap));
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

.o-logo {
  width: var(--logo-width);
  display: block;
  z-index: 2;
}
.page-template-template-landing-no-header .o-logo {
  margin: 0 auto;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--container);
  width: 100%;
  padding: 0 var(--default-spacing);
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
.c-header .o-wrapper {
  border-top: var(--page-border-width) solid #363f4d;
  max-width: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
}
.c-header .o-wrapper::after {
  display: none !important;
}

.o-wrapper--wide {
  max-width: var(--wide-container);
  /* stylelint-disable */
  /* stylelint-enable */
}
@media (min-width: 768px) {
  .o-wrapper--wide .o-layout--2 .o-layout__item:last-child > * {
    max-width: 660px;
  }
  .o-wrapper--wide .o-layout--2.o-layout--reverse .o-layout__item:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .o-wrapper--wide .o-layout--2.o-layout--reverse .o-layout__item:last-child > * {
    width: 100%;
  }
}

.o-wrapper--body {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.o-wrapper--body .c-main {
  flex: 1;
  overflow: hidden;
}

.o-wrapper--page-content {
  position: relative;
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}

*:not(.c-categories-nav) + .o-wrapper--page-content::before {
  content: "";
  display: block;
  position: absolute;
  top: -2rem;
  left: 50%;
  width: 1px;
  height: 4rem;
  background-color: #1f1f1f;
}
@media (min-width: 768px) {
  *:not(.c-categories-nav) + .o-wrapper--page-content::before {
    height: 8rem;
    top: -4rem;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.menu-item--btn .c-main-nav__link,
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.9rem 1.2rem 0.7rem;
  min-width: 10rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
  border: 1px solid transparent;
  white-space: nowrap;
  touch-action: manipulation;
  user-select: none;
  width: auto;
  font-family: "berlin", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 0.8rem;
  line-height: 1rem;
}
@media (max-width: 519.98px) {
  .menu-item--btn .c-main-nav__link,
.c-btn {
    font-size: 12px;
  }
}
.menu-item--btn .c-main-nav__link::-moz-focus-inner,
.c-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.menu-item--btn .is-disabled.c-main-nav__link, .menu-item--btn .c-main-nav__link:disabled,
.c-btn.is-disabled,
.c-btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}

/* Style variants - very important
   ========================================================================== */
.c-post__password-form input[type=submit],
.c-btn--primary {
  color: #fff;
  background-color: #363f4d;
}
.c-post__password-form input[type=submit]:hover, .c-post__password-form input[type=submit]:active, .c-post__password-form input[type=submit]:focus,
.c-btn--primary:hover,
.c-btn--primary:active,
.c-btn--primary:focus {
  border: 1px solid #363f4d;
  text-decoration: none;
  /* [4] */
  color: #363f4d;
  background-color: #fff;
}

section[class*="--navy"] .c-btn,
.c-btn--secondary {
  color: #363f4d;
  background-color: #fff;
}
section[class*="--navy"] .c-btn:hover, section[class*="--navy"] .c-btn:active, section[class*="--navy"] .c-btn:focus,
.c-btn--secondary:hover,
.c-btn--secondary:active,
.c-btn--secondary:focus {
  border: 1px solid #fff;
  text-decoration: none;
  /* [4] */
  color: #fff;
  background-color: #363f4d;
}

@property --p {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}
@property --r {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}
.menu-item--btn .c-main-nav__link,
.c-btn--cta {
  /*  needed for firefox to have a valid output */
  --p: 0%;
  --r: 0%;
  transition: --p 800ms, --r 800ms;
  color: #fff;
  background: conic-gradient(at var(--p) var(--r), #f9a620, #ffaa21, #ffad29, #f9a620);
}
.menu-item--btn .c-main-nav__link:hover, .menu-item--btn .c-main-nav__link:active, .menu-item--btn .c-main-nav__link:focus,
.c-btn--cta:hover,
.c-btn--cta:active,
.c-btn--cta:focus {
  border: 1px solid #f9a620;
  text-decoration: none;
  /* [4] */
  color: #fff;
  --p: 125%;
  --r: 100%;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--block {
  display: block;
  width: 100%;
}
.c-btn--block + .c-btn--block {
  margin-top: 0.25rem;
}

.c-btn--link {
  background-color: transparent;
  color: #363f4d;
}

.c-buttons {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
@media (max-width: 519.98px) {
  .c-buttons {
    flex-direction: column;
  }
}

.c-buttons:has(p) {
  flex-direction: column;
  align-items: center;
}
.c-buttons:has(p) .c-btn--text {
  font-size: 90%;
}

.menu-item--btn {
  position: relative;
}
@media (min-width: 1024px) {
  .menu-item--btn {
    top: -3px;
  }
}

.menu-item--btn .c-main-nav__link {
  min-width: auto;
  font-size: 0.7rem;
}

/* stylelint-disable */
.c-categories-nav {
  position: relative;
  padding-bottom: 1rem;
  background-color: #f7f5f3;
}
.c-categories-nav .secondary-title, .c-categories-nav .wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.c-categories-nav .form__label,
.c-categories-nav .cat-item {
  margin: 0;
}
.c-categories-nav .cat-item {
  position: relative;
}
.c-categories-nav .cat-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1rem;
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.c-categories-nav .cat-item a {
  color: #707070;
  transition: all 300ms ease-in-out;
}
.c-categories-nav .cat-item a:hover {
  color: #1f1f1f;
}
.c-categories-nav .cat-item.current-cat a {
  color: #1f1f1f;
}
.c-categories-nav .cat-item.current-cat::after {
  background-color: #707070;
}

.c-categories-nav__wrapper {
  margin-bottom: var(--heading-spacing);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  padding-bottom: 1rem;
}

.c-categories-nav__list {
  display: flex;
  gap: 1rem;
}
.c-categories-nav__list a {
  text-decoration: none;
}

/* stylelint-enable */
.c-columns__images {
  display: flex;
}
@media (min-width: 768px) {
  .c-columns__images {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}

.c-columns__picture {
  flex: 1 0 50%;
  position: relative;
}

.c-columns__image {
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.c-columns__images--1 .c-columns__image {
  max-width: 650px;
  margin: 0 auto;
  display: block;
}

.c-columns__picture + .c-columns__picture:last-child .c-columns__image {
  position: relative;
  left: -20%;
  margin-top: 50%;
}
@media (max-width: 767.98px) {
  .c-columns__picture + .c-columns__picture:last-child .c-columns__image {
    left: -18%;
    width: 120%;
    max-width: none;
  }
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-embed--youtube {
  margin: 0 auto;
  max-width: 1024px;
}
.c-embed--youtube iframe {
  width: 100%;
  aspect-ratio: 1280/720;
  height: auto;
}

@supports not (aspect-ratio: auto) {
  .c-embed--youtube {
    padding-top: 56%;
    height: 0;
    position: relative;
  }
  .c-embed--youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.c-faq__wrapper {
  display: flex;
  flex-direction: column;
}

.c-faq__title,
.c-faq__content {
  line-height: 1.2;
}

.c-faq__title {
  border-bottom: 1px solid #f7f5f3;
  padding: 0.8rem 1.5rem;
  position: relative;
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  background-color: #fff;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.c-faq__title::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=");
  transform: rotate(-90deg);
  transition: all 300ms ease-in-out;
}
.c-faq__title[aria-expanded=true] {
  background-color: #f7f5f3;
}
.c-faq__title[aria-expanded=true]::before {
  transform: rotate(0deg);
}

.c-faq__content {
  display: grid;
  grid-template-rows: 0fr;
  font-size: 0.9rem;
  margin: 0;
  transition: all 300ms ease-in-out;
}
.c-faq__content:last-child {
  border: none;
}
.c-faq__content > div {
  overflow: hidden;
  padding: 0 1rem;
}
[aria-expanded=true] + .c-faq__content {
  grid-template-rows: 1fr;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #f7f5f3;
}

.c-featured-images {
  margin-bottom: var(--heading-spacing);
}
.c-main .c-featured-images:first-child {
  padding-top: var(--heading-spacing);
  margin-bottom: 0;
}

.c-featured-images__gallery {
  display: flex;
  gap: 1rem;
  max-width: var(--wide-container);
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}
.o-wrapper + .c-featured-images__gallery {
  margin-top: var(--heading-spacing);
}
.c-featured-images__gallery .glide__slides {
  align-items: center;
}

@media (max-width: 519.98px) {
  .c-featured-images__gallery:not(:has(.glide__track), .c-featured-images__gallery--3) {
    flex-wrap: wrap;
  }
  .c-featured-images__gallery:not(:has(.glide__track), .c-featured-images__gallery--3) > * {
    flex: 1 1 45%;
  }
}

/* stylelint-disable */
section[class*="--cream"] + .c-featured-images,
section[class*="--navy"] + .c-featured-images {
  position: relative;
  z-index: 0;
}
section[class*="--cream"] + .c-featured-images::before,
section[class*="--navy"] + .c-featured-images::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 50%;
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

section[class*="--cream"] + .c-featured-images::before {
  background: conic-gradient(from -90deg at top center, #f7f5f3, #faf7f5);
}

section[class*="--navy"] + .c-featured-images::before {
  background: conic-gradient(from -90deg at top center, #363f4d, #394352);
}

/* stylelint-enable */
.c-featured-images__img {
  border-radius: 3px;
}

.c-featured-images__gallery--2 {
  justify-content: center;
}
@media (min-width: 768px) {
  .c-featured-images__gallery--2 > * {
    max-width: 40vw;
  }
}
@media (min-width: 1024px) {
  .c-featured-images__gallery--2 > * {
    max-width: 25vw;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  position: relative;
  padding: 2rem 0 1rem;
  font-size: 0.875rem;
  line-height: 1.2;
  background-color: #f7f5f3;
}
.c-footer a {
  color: #707070;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.c-footer a:hover {
  color: #1f1f1f;
  text-decoration: underline;
}
.nav-is-open .c-footer {
  z-index: -1;
}

.main--bg + .c-footer {
  background-color: #fff;
}

.c-footer__navigation .c-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 520px) {
  .c-footer__navigation .c-menu {
    flex-direction: row;
  }
}
.c-footer__navigation .c-menu__item {
  margin: 0;
}

.c-footer__socials + .c-footer__navigation,
.c-footer__navigation + p {
  margin-top: 1rem;
}

.c-footer p {
  color: #707070;
  text-align: center;
}

.c-footer__socials {
  text-align: center;
}
.c-footer__socials .socials__link {
  display: inline-block;
}
.c-footer__socials .socials__icon {
  display: block;
  font-size: 1.4rem;
}
.c-footer__socials .socials__icon svg {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}

.c-footer__copyright {
  margin-top: var(--layout-gap);
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
}
@media (max-width: 519.98px) {
  .c-footer__copyright {
    flex-direction: column;
  }
}

.c-gallery .c-wysiwyg {
  padding: 1rem;
}

.c-gallery__picture {
  margin: 5px 0;
  position: relative;
}
@media (max-width: 519.98px) {
  .c-gallery__picture {
    width: calc(100% - 10px) !important;
  }
}
.c-gallery__picture.is-loading::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url("~assets/images/icon-tail-spin.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.c-gallery__img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

[data-columns="2"] .grid-sizer,
[data-columns="2"] .c-gallery__picture {
  width: calc(50% - 10px);
}
[data-columns="2"] .c-gallery__picture.width2,
[data-columns="2"] .c-gallery__picture.width3,
[data-columns="2"] .c-gallery__picture.width4 {
  width: calc(100% - 10px);
}

[data-columns="3"] .grid-sizer,
[data-columns="3"] .c-gallery__picture {
  width: calc(33% - 10px);
}
[data-columns="3"] .c-gallery__picture.width2 {
  width: calc(66% - 10px);
}
[data-columns="3"] .c-gallery__picture.width3,
[data-columns="3"] .c-gallery__picture.width4 {
  width: calc(100% - 10px);
}

[data-columns="4"] .grid-sizer,
[data-columns="4"] .c-gallery__picture {
  width: calc(25% - 10px);
}
[data-columns="4"] .c-gallery__picture.width2 {
  width: calc(50% - 10px);
}
[data-columns="4"] .c-gallery__picture.width3 {
  width: calc(75% - 10px);
}
[data-columns="4"] .c-gallery__picture.width4 {
  width: calc(100% - 10px);
}

.gallery-item .gallery-icon {
  display: block;
  width: 100%;
  height: auto;
}
.gallery-item .gallery-icon img {
  display: block;
  width: 100%;
  height: auto;
  border-color: transparent !important;
}

.c-gallery__picture.grid-item--width2,
.c-gallery__picture.grid-item--width2 img {
  width: 100%;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: sticky;
  top: 0;
  left: var(--page-border-width);
  width: calc(100% - 2 * var(--page-border-width));
  height: var(--header-height);
  background-color: #fff;
  transition: all 300ms ease-in-out;
  z-index: 2;
}
.c-header.is-scrolled {
  background-color: rgba(255, 255, 255, 0.7);
}
.page-template-template-landing-no-header .c-header {
  background-color: rgba(255, 255, 255, 0.5);
}

/**
 * Hamburger menu
 */
.c-header__burger {
  position: absolute;
  top: 50%;
  right: 1rem;
  z-index: 550;
  transition: all 300ms ease-in-out;
  transform: translateY(-50%);
  display: none;
  border: 0;
  background: none;
}
@media (max-width: 1023.98px) {
  .c-header__burger {
    display: block;
  }
}

.c-header__burger-line {
  width: 30px;
  height: 2px;
  background-color: #363f4d;
  display: none;
  margin: 6px auto;
  transition: all 300ms ease-in-out;
}
@media (max-width: 1023.98px) {
  .c-header__burger-line {
    display: block;
  }
}
.c-header__burger.is-clicked .c-header__burger-line:nth-child(2) {
  opacity: 0;
}
.c-header__burger.is-clicked .c-header__burger-line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.c-header__burger.is-clicked .c-header__burger-line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 1023.98px) {
  .c-header__navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 300ms ease-in-out;
  }
  .nav-is-open .c-header__navigation {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 1;
    overflow: auto;
  }
}

.c-heading {
  margin-bottom: var(--heading-spacing);
  text-align: center;
}
.c-tease .c-heading {
  margin-bottom: 1rem;
}
.c-heading > * {
  margin-bottom: 0.25rem;
}
.c-heading:last-child,
.c-heading > *:last-child {
  margin-bottom: 0;
}

.c-heading__title {
  word-break: break-word;
}
@media (min-width: 768px) {
  .c-heading__title {
    font-size: 2rem;
    line-height: 0.8;
  }
}

.c-heading__archive-title {
  font-size: 1.75rem;
  word-break: break-word;
}
@media (min-width: 768px) {
  .c-heading__archive-title {
    font-size: 2.25rem;
  }
}

.c-heading__tagline {
  display: inline-block;
}

.c-heading--page {
  padding-top: calc(var(--section-spacing));
  padding-bottom: var(--section-spacing);
  background-color: #f7f5f3;
}

.c-heading__link {
  text-decoration: none;
  color: #707070;
  transition: all 300ms ease-in-out;
  position: relative;
}
.c-heading__link::before {
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: 1px;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  transition: all 300ms ease-in-out;
}
.c-heading__link:hover {
  color: #1f1f1f;
}
.c-heading__link:hover::before {
  width: 2rem;
  background-color: #1f1f1f;
}
.c-heading__link::after {
  content: ",";
}
.c-heading__link:last-child::after {
  content: "";
}

.c-heading__post-thumbnail-picture {
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .c-heading__post-thumbnail-picture {
    padding-top: 0;
  }
}
.c-heading__post-thumbnail-picture img {
  max-height: 80vh;
}

.c-heading--post {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
  text-align: center;
  background-color: #f7f5f3;
  /* stylelint-disable */
  /* stylelint-enable */
}
.c-heading--post:has(.c-heading__post-thumbnail-picture) {
  padding-top: 0;
}
.c-heading--post .c-heading {
  position: relative;
  padding: 0.75rem 1rem;
  width: auto;
  min-width: 50vw;
  display: inline-block;
  text-align: center;
  z-index: 1;
  background-color: #fff;
}
@media (min-width: 768px) {
  .c-heading--post .c-heading {
    padding: 0.75rem 4rem;
  }
}
.c-heading--post .c-heading::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -0.5rem;
  border: 1px solid #1f1f1f;
  z-index: -1;
}
.c-heading--post .c-heading__post-thumbnail + .o-wrapper .c-heading {
  margin-top: -2rem;
}

.c-heading--tease:not(:has(.secondary-title)) {
  padding-top: calc(var(--heading-spacing) / 2);
}

.c-hero {
  position: relative;
  background-color: #f7f5f3;
}

.c-hero__content {
  padding: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .c-hero__content {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .c-hero__content,
.c-hero__content .main-title,
.c-hero__content .secondary-title,
.c-hero__content .wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.c-hero__content .form__label {
    color: #fff;
  }
}
@media (max-width: 767.98px) {
  .c-hero__content .main-title {
    font-size: 1.5rem;
  }
}
.c-hero__content .secondary-title, .c-hero__content .wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.c-hero__content .form__label {
  font-size: 0.8rem;
}
@media (max-width: 519.98px) {
  .c-hero__content .secondary-title, .c-hero__content .wpcf7-not-valid-tip.wpcf7-not-valid-tip,
.c-hero__content .form__label {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .valign-center .c-hero__content {
    justify-content: center;
  }
}

.overlay-image .c-hero__slider::after {
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.c-hero__slider {
  position: relative;
}

.c-hero__slide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;
}
@media (min-width: 1024px) {
  .c-hero__slide {
    padding-top: 75%;
  }
}
@media (min-width: 1280px) {
  .c-hero__slide {
    padding-top: 75vh;
  }
}
.c-hero__slide .c-hero__slide-picture,
.c-hero__slide .c-hero__slide-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.c-infobar {
  padding: 0.75em 0;
  font-size: 0.8rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  background-color: #363f4d;
}
.c-infobar a {
  text-decoration: underline;
}
.c-infobar a,
.c-infobar a:hover {
  color: #fff;
}
.c-infobar a:hover {
  text-decoration: none;
}
.woocommerce-page:not(.woocommerce-account) .c-infobar {
  display: none;
}

.c-infobar--pulse {
  position: relative;
  background-color: transparent;
}
.c-infobar--pulse::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.nav--header .current-menu-item a::before,
.nav--header a:hover::before {
  width: 100%;
}

.c-main-nav {
  display: flex;
  align-items: baseline;
  margin: 0 0 2em 0;
  gap: 0.5rem;
}
@media (max-width: 1023.98px) {
  .c-main-nav {
    padding: var(--header-height) 0;
    display: block;
  }
}
@media (max-height: 540px) {
  .c-main-nav {
    margin: auto;
  }
}

.c-main-nav__item,
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}

@media (min-width: 1024px) {
  .c-main-nav__subitem {
    border-bottom: 1px solid #1f1f1f;
  }
}

.c-main-nav__sublink {
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1024px) {
  .c-main-nav__sublink:hover {
    color: #fff;
    background-color: #363f4d;
  }
}
@media (max-width: 1023.98px) {
  .c-main-nav__sublink {
    padding: 1rem 0.25rem 0.5rem 1.25rem;
  }
}
@media (max-height: 540px) {
  .c-main-nav__sublink {
    padding: 0.5rem 0.25rem 0.15rem;
  }
}

/* stylelint-disable */
.c-main-nav__link,
.c-main-nav__sublink {
  position: relative;
  display: block;
  padding: 0.5rem 0.65rem 0.5rem;
  text-transform: uppercase;
  font-family: "berlin", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;
}
@media (max-width: 519.98px) {
  .c-main-nav__link,
.c-main-nav__sublink {
    font-size: 12px;
  }
}
.c-main-nav__link[type=button],
.c-main-nav__sublink[type=button] {
  background: none;
  border: 0;
}
.c-main-nav__link:hover,
.c-main-nav__sublink:hover {
  text-decoration: none;
}
.current-menu-item .c-main-nav__link,
.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #1f1f1f;
}
@media (max-height: 540px) {
  .c-main-nav__link,
.c-main-nav__sublink {
    padding: 0.5rem 0.25rem 0.15rem;
  }
}

.menu-item-has-children {
  position: relative;
}
.menu-item-has-children .c-main-nav__link::after {
  content: "";
  width: 14px;
  height: 10px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=");
}

.c-main-nav__link::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid transparent;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.current-menu-item .c-main-nav__link::before {
  height: 100%;
  border-bottom-color: #1f1f1f;
}
.menu-item:hover .c-main-nav__link::before {
  height: 100%;
  border-bottom-color: #1f1f1f;
  background-color: #fff;
}

/* stylelint-enable */
.c-main-nav__dropdown-wrapper {
  width: 100%;
  min-width: 180px;
  transition: all 0.3s ease-in-out;
  display: block;
  will-change: max-height;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .c-main-nav__dropdown-wrapper {
    max-height: 0;
    position: absolute;
    background-color: #fff;
  }
}
@media (min-width: 1024px) {
  .c-main-nav__item.menu-item-has-children:hover .c-main-nav__dropdown-wrapper {
    max-height: 300px;
  }
}
@media (max-width: 1023.98px) {
  .c-main-nav__dropdown-wrapper {
    padding-left: 1.5rem;
  }
}

.c-pagination {
  margin-top: var(--section-spacing);
}
.c-pagination a {
  color: #1f1f1f;
}

.c-pagination .page-number {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  aspect-ratio: 1;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  font-family: "berlin", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 700;
  color: #1f1f1f;
  background: transparent;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}
@supports not (aspect-ratio: auto) {
  .c-pagination .page-number {
    height: 30px;
  }
}
.c-pagination .page-number:hover, .c-pagination .page-number.current {
  border-bottom: 1px solid #1f1f1f;
}

.c-pagination,
.c-pagination__list {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.c-pagination__list {
  list-style: none inside;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-post__content .o-wrapper {
    padding: 0 8rem;
  }
}
.c-post__content .gallery,
.c-post__content .size-large {
  width: 100%;
}
@media (min-width: 768px) {
  .c-post__content .gallery,
.c-post__content .size-large {
    margin-left: -6rem;
    margin-right: -6rem;
    width: calc(100% + 12rem);
  }
}

.c-post__password-form {
  text-align: center;
}
.c-post__password-form input {
  max-width: 360px;
}
.c-post__password-form input[type=submit] {
  cursor: pointer;
}
.c-post__password-form label,
.c-post__password-form input {
  display: block;
  margin: 5px auto;
}

.c-post__pagination {
  position: relative;
  padding: var(--heading-spacing) 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #1f1f1f;
}
.c-post__pagination .prev,
.c-post__pagination .next {
  margin: 0 1rem;
}
.c-post__pagination .next {
  text-align: right;
}
.c-post__pagination:has(.next):not(:has(.prev)) {
  justify-content: end;
}

.c-post__pagination-link {
  position: relative;
  font-size: calc(1.15 * var(--font-size));
  text-decoration: none;
}
.c-post__pagination-link::after {
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: 1px;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  transition: all 300ms ease-in-out;
}
.c-post__pagination-link:hover::after {
  width: 100%;
  background-color: #1f1f1f;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-quote {
  padding: 2rem 0;
  text-align: center;
}
.c-quote .main-title {
  font-weight: 400;
}

section[class*="--cream"] {
  background: conic-gradient(from -90deg at bottom center, #faf7f5, #f7f5f3);
}

section[class*="--navy"] {
  background: conic-gradient(from -90deg at bottom center, #394352, #363f4d);
}
section[class*="--navy"] .c-wysiwyg,
section[class*="--navy"] .main-title,
section[class*="--navy"] .main-title a,
section[class*="--navy"] .secondary-title,
section[class*="--navy"] .wpcf7-not-valid-tip.wpcf7-not-valid-tip,
section[class*="--navy"] .form__label,
section[class*="--navy"] p,
section[class*="--navy"] .c-tease .read-more {
  color: #fff;
}
section[class*="--white"] {
  background-color: #fff;
}

section[class*=c-section]:not(.c-quote) {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}
section[class*=c-section]:not(.c-quote) .c-buttons {
  margin-top: var(--section-spacing);
}

@media (min-width: 768px) {
  section[class*=c-gallery] .c-heading + .c-buttons {
    margin-top: -2rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 767.98px) {
  section[class*=c-gallery] .c-heading + .c-buttons {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
}

.c-tease .main-title a {
  text-decoration: none;
}
.c-tease .main-title a:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .c-tease .c-heading__title {
    font-size: 1.5rem;
  }
}

.o-grid__item .c-tease {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.o-grid__item .c-tease > * {
  flex: 1;
}
.o-grid__item .c-tease > *:last-child {
  position: relative;
  justify-self: flex-end;
}

.c-tease__picture {
  position: relative;
  display: block;
  overflow: hidden;
}
.c-tease__picture::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  pointer-events: none;
}
.c-tease--post:hover .c-tease__picture::after, .c-tease__link:hover .c-tease__picture::after {
  background-color: transparent;
}

.c-tease__img {
  display: block;
  transform: scale(1);
  transition: all 300ms ease-in-out;
}
.c-tease--post:hover .c-tease__img, .c-tease__link:hover .c-tease__img {
  transform: scale(1.05);
}

.c-tease__link {
  display: block;
  margin-bottom: 1rem;
}

.c-tease .read-more {
  display: block;
  line-height: 1.5rem;
  text-align: center;
  color: #363f4d;
  font-weight: 700;
  text-decoration: none;
  margin: 1rem auto 0;
}
.c-tease .read-more::after {
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: 1px;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  transition: all 300ms ease-in-out;
}
.c-tease .read-more:hover {
  text-decoration: none;
}
.c-tease .read-more:hover::after {
  width: 4rem;
  background-color: #1f1f1f;
}

.c-testimonial-item {
  display: flex;
}
@media (max-width: 519.98px) {
  .c-testimonial-item {
    flex-direction: column;
    align-items: center;
  }
}

.c-testimonial-item + .c-testimonial-item {
  margin-top: 1rem;
}

.c-testimonial-item__image {
  width: 25%;
  position: relative;
  flex: 0 0 25%;
}
@media (max-width: 519.98px) {
  .c-testimonial-item__image {
    width: 50%;
    flex: 1 1 50%;
    margin-bottom: -4rem;
  }
}
@media (min-width: 520px) {
  .c-testimonial-item--right .c-testimonial-item__image {
    order: 2;
  }
}

.c-testimonial-item__img {
  border-radius: 3px;
}

.c-testimonial-item__content {
  flex: 1 1 auto;
  width: auto;
  margin: 4rem;
  margin-left: calc(-25% + 4rem);
  padding: 4rem;
  padding-left: 25%;
  font-size: 1rem;
  background: conic-gradient(from -90deg at bottom center, #faf7f5, #f7f5f3);
}
@media (max-width: 767.98px) {
  .c-testimonial-item__content {
    margin-right: 0;
  }
}
@media (max-width: 519.98px) {
  .c-testimonial-item__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: calc(-25% + 2rem);
    padding: 2rem;
    padding-left: 25%;
  }
}
@media (max-width: 519.98px) {
  .c-testimonial-item__content {
    margin: 0;
    padding: 6rem 2rem 2rem 2rem;
  }
}
.c-testimonial-item--right .c-testimonial-item__content {
  margin: 4rem;
  margin-right: calc(-25% + 4rem);
  padding: 4rem;
  padding-right: 25%;
}
@media (max-width: 767.98px) {
  .c-testimonial-item--right .c-testimonial-item__content {
    margin-left: 0;
  }
}
@media (max-width: 519.98px) {
  .c-testimonial-item--right .c-testimonial-item__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: calc(-25% + 2rem);
    padding: 2rem;
    padding-right: 25%;
  }
}
@media (max-width: 519.98px) {
  .c-testimonial-item--right .c-testimonial-item__content {
    margin: 0;
    padding: 6rem 2rem 2rem 2rem;
  }
}
@media (min-width: 520px) {
  .c-testimonial-item--right .c-testimonial-item__content {
    order: 1;
  }
}

.c-testimonial-item__author {
  margin-top: 1rem;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}