/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */
[class*='o-grid'] {
  display: grid;
  gap: calc(2 * var(--layout-gap)) var(--layout-gap);
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
}
