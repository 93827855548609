.c-testimonial-item {
  display: flex;

  @include bp-down(small) {
    flex-direction: column;
    align-items: center;
  }
}

.c-testimonial-item + .c-testimonial-item {
  margin-top: $spacing;
}

.c-testimonial-item__image {
  width: 25%;
  position: relative;
  flex: 0 0 25%;

  @include bp-down(small) {
    width: 50%;
    flex: 1 1 50%;
    margin-bottom: -4rem;
  }

  .c-testimonial-item--right & {
    @include bp(small) {
      order: 2;
    }
  }
}

.c-testimonial-item__img {
  border-radius: $global-radius;
}

.c-testimonial-item__content {
  flex: 1 1 auto;
  width: auto;
  margin: 4rem;
  margin-left: calc(-25% + 4rem);
  padding: 4rem;
  padding-left: 25%;
  font-size: 1rem;
  background: conic-gradient(
    from -90deg at bottom center,
    $color-cream-lighter,
    $color-cream
  );

  @include bp-down(medium) {
    margin-right: 0;
  }

  @include bp-down(small) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: calc(-25% + 2rem);
    padding: 2rem;
    padding-left: 25%;
  }

  @include bp-down(small) {
    margin: 0;
    padding: 6rem 2rem 2rem 2rem;
  }

  .c-testimonial-item--right & {
    margin: 4rem;
    margin-right: calc(-25% + 4rem);
    padding: 4rem;
    padding-right: 25%;

    @include bp-down(medium) {
      margin-left: 0;
    }

    @include bp-down(small) {
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-right: calc(-25% + 2rem);
      padding: 2rem;
      padding-right: 25%;
    }

    @include bp-down(small) {
      margin: 0;
      padding: 6rem 2rem 2rem 2rem;
    }

    @include bp(small) {
      order: 1;
    }
  }
}

.c-testimonial-item__author {
  margin-top: $spacing;
}
