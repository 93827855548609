.c-hero {
  position: relative;
  background-color: $color-cream;
}

.c-hero__content {
  padding: 2 * $spacing;
  text-align: center;

  @include bp(medium) {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);

    &,
    .main-title,
    .secondary-title {
      color: $color-white;
    }
  }

  @include bp-down(medium) {
    .main-title {
      font-size: 1.5rem;
    }
  }

  .secondary-title {
    font-size: 0.8rem;

    @include bp-down(small) {
      font-size: 12px;
    }
  }
}

.valign-center .c-hero__content {
  @include bp(medium) {
    justify-content: center;
  }
}

.overlay-image .c-hero__slider::after {
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.c-hero__slider {
  position: relative;
}

.c-hero__slide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;

  @include bp(large) {
    padding-top: 75%;
  }

  @include bp(xlarge) {
    padding-top: 75vh;
  }

  .c-hero__slide-picture,
  .c-hero__slide-img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
