/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */
*:focus {
  outline-width: 1px;
  outline-style: dashed;
  outline-color: $color-info;
}

/**
 * Consistent indentation for lists.
 */

dd,
ol,
ul {
  margin-left: var(--default-spacing);
}

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: var(--default-spacing);

  &:last-child {
    margin-bottom: 0;
  }
}
